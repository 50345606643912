var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-header",
        { staticClass: "container-header" },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _c("svg-icon", {
                staticClass: "sidebar-logo",
                attrs: { "icon-class": "moonton" },
              }),
              _vm._v("\n      ODIN CMDB\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-menu",
            {
              ref: "mainmenu",
              staticClass: "header-menu",
              attrs: {
                mode: "horizontal",
                "default-active": _vm.defaultMenu,
                router: "",
                "background-color": "#182132",
                "text-color": "#979ba5",
                "active-text-color": "#fff",
              },
            },
            [
              _c("el-menu-item", { attrs: { index: "/dashboard" } }, [
                _vm._v("首页"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "/business" } }, [
                _vm._v("业务资源"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "/global" } }, [
                _vm._v("全局资源"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "/biz" } }, [
                _vm._v("业务管理"),
              ]),
              _vm._v(" "),
              _c("el-menu-item", { attrs: { index: "/log" } }, [
                _vm._v("运营分析"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "info" },
            [
              _c(
                "el-dropdown",
                { staticClass: "userinfo", attrs: { trigger: "click" } },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _vm._v("\n          " + _vm._s(_vm.name)),
                    _c("i", {
                      staticClass: "el-icon-arrow-down el-icon--right",
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { icon: "el-icon-switch-button" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [_vm._v("注销")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("el-main", [
        _c(
          "section",
          { staticClass: "app-main" },
          [_c("router-view", { key: _vm.key })],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }